import React from 'react'
import { Empty as AntdEmpty } from 'antd'

type Props = {
  type: 'small' | 'large'
  message: string
}

const Empty: React.FC<Props> = (props) => {
  const { type, message } = props
  if (type === 'small') {
    return <AntdEmpty description={message} />
  }
  if (type === 'large') {
    return (
      <AntdEmpty
        image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
        className="Empty"
        imageStyle={{ height: 150 }}
        description={props.message}
      />
    )
  }
  return null
}

export default Empty
