import React from 'react'
import ReactDOM from 'react-dom'
import { ConfigProvider } from 'antd'
import { CookiesProvider } from 'react-cookie'
import * as serviceWorker from './serviceWorker'
import ruRU from 'antd/es/locale/ru_RU'
import App from './App'
import './index.less'

ReactDOM.render((
  <ConfigProvider locale={ruRU}>
    <CookiesProvider>
      <App />
    </CookiesProvider>
  </ConfigProvider>
), document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()