import Empty from './components/Empty'
import React, { Suspense, lazy } from 'react'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'
import './App.less'

const Auth = lazy(() => import('./components/Auth'))
const Form = lazy(() => import('./components/Form'))
const Viewer = lazy(() => import('./components/Player/Viewer'))
const Account = lazy(() => import('./components/Account'))
const Landing = lazy(() => import('./components/Landing'))
const Speaker = lazy(() => import('./components/Player/Speaker'))

const App = () => {
  return (
    <BrowserRouter>
      <div className="App">
        <Suspense fallback={<div/>}>
          <Switch>
            <Route exact={true} path="/"><Redirect to="/account" /></Route>
            <Route exact={true} path="/signin"><Auth /></Route>
            <Route exact={true} path="/create"><Form mode="create"/></Route>
            <Route exact={true} path="/account"><Account /></Route>
            <Route exact={true} path="/viewer/:webinarId"><Viewer /></Route>
            <Route exact={true} path="/landing/:webinarId"><Landing /></Route>
            <Route exact={true} path="/speaker/:webinarId"><Speaker /></Route>
            <Route exact={true} path="/edit/:webinarId"><Form mode="edit" /></Route>
            <Route><Empty type="large" message="Здесь ничего нет" /></Route>
          </Switch>
        </Suspense>
      </div>
    </BrowserRouter>
  )
}

export default App
